import styled from 'styled-components';
import variables from '../../../_metronic/variables.scss'

export const LoadingComponent = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;

img {
  margin-left: calc(100vw - 100%);
  margin-bottom: 30px;
  max-width: 250px;
}

hidden {
  opacity: 0;
  visibility: hidden;
}

/* .spinner {
  animation: rotate 2s linear infinite;
  margin-left: calc(100vw - 100%);
  width: 50px;
  height: 50px;
}

.spinner .path {
  stroke: #ffffff;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
} */

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.loader {
  width: 120px;
  height: 75px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between
}

.loader span {
  font-size: 22px;
  text-transform: uppercase;
  margin: auto;
}

.ball1 {
  background-color: ${() => variables.primaryColor1};
}

.ball2 {
  background-color: ${() => variables.primaryColor2};
}

.ball3 {
  background-color: ${() => variables.primaryColor3};
}

.ball {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  animation: bounce .5s alternate infinite;
}

.ball:nth-child(2) {
  animation-delay: .16s;
}

.ball:nth-child(3) {
  animation-delay: .32s;
}

@keyframes bounce {
  from {
    transform: scaleX(1.25);
  }
  to {
    transform: translateY(-50px) scaleX(1);
  }
}

`;
