import styled from "styled-components";

import variables from "../../../variables.scss";

export const Aside = styled.div`
  height: 100vh;
  position: fixed;
  z-index: 1;
  width: 85px;
  background-color: ${variables.backgroundAsideAdmin};
  flex-direction: column;
  padding: 10px 0;
  justify-content: flex-start;
  align-content: center;
  display: none;
  @media (min-width: 769px) {
    display: flex;
  }

  .logo {
    display: flex;
    justify-content: center;
  }

  .logo img {
    width: 55px;
  }

  .links {
    display: flex;
    flex-direction: column;
    color: ${variables.asideColorPrimary};
    align-items: center;
    padding-top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #bcbcbc;
      border-radius: 20px;
      height: 120px;
    }
  }

  .links a,
  .submenu .submenu-title {
    color: ${variables.asideColorPrimary};
    display: flex;
    justify-content: center;
    padding: 12px 31.5px;
    width: 100%;
    gap: 10px;
  }

  .submenu {
    width: 100%;
    cursor: pointer;
  }

  .submenu {
    justify-content: flex-start !important;
  }

  .links a i,
  .submenu i {
    font-size: 22px;
    display: flex;
  }

  .links a span,
  .submenu .submenu-title span {
    display: none;
    font-size: 14px;
    height: 100%;
    align-items: center;
  }

  .submenu .submenu-itens {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    overflow: hidden;
    height: 0;
    animation: animationSubmenuRetrai 0.1s ease-out alternate;
  }

  .submenu .submenu-itens a {
    padding: 0 10px 0 65px;
    height: 35px;
  }

  .submenu .submenu-itens a span {
    display: flex;
    align-items: center;
  }

  &:hover {
    width: 280px;
    transition: 0.4s;
  }

  &:hover .logo img {
    width: 85px;
  }

  &:hover .links a,
  &:hover .submenu .submenu-title {
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  &:hover .links a:hover,
  &:hover .links .submenu .submenu-title:hover,
  &:hover
    .links
    .submenu
    .submentu-itens
    a:hover
    ~ &:hover
    .links
    .submenu
    .submenu-title {
    background-color: ${variables.asideBackgroundSelectedPrimary};
    color: ${variables.asideColorSelectedPrimary};
  }

  &:hover .links a span,
  &:hover .submenu .submenu-title span {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
  }

  .submenu:hover .submenu-itens {
    animation: animationSubmenu 0.3s ease-out alternate;
    height: auto;
  }

  .submenu:hover .submenu-itens a {
    overflow: hidden;
    visibility: visible;
    cursor: pointer;
  }

  &:hover .submenu .submenu-itens a span {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-flex;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
  }

  .selected {
    background-color: ${variables.asideBackgroundSelectedPrimary};
    color: ${variables.asideColorSelectedPrimary} !important;
  }

  .submenu-selected {
    background-color: ${variables.asideBackgroundSelectedPrimary};
    color: ${variables.asideColorSelectedPrimary};
  }

  @keyframes animationSubmenu {
    0% {
      height: 0;
    }

    100% {
      height: 100%;
    }
  }

  @keyframes animationSubmenuRetrai {
    100% {
      height: 100%;
    }
    0% {
      height: 0;
    }
  }
`;
