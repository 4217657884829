import React, { useEffect, useState } from 'react';

import { LoadingComponent } from './styles';
import { CMSService } from '../../services';

function LoadingPage() {
  const [logo, setLogo] = useState('')

  const loadFromCMS = async () => {
    try {
      const [image] = await Promise.all([
        CMSService.getContentFromYear('logo-admin', 'slug,title,metadata')
      ]);

      const logourl = image?.metadata?.logo?.url;
      setLogo(logourl)
    } catch (e) {
      console.error(e);
    }
  }


  useEffect(() => {
    loadFromCMS();
  },[])

  return (
    <LoadingComponent >
      {logo && <img src={logo} alt="Devaria logo" />}
      <div className="loader">
        <div className="ball ball1"></div>
        <div className="ball ball2"></div>
        <div className="ball ball3"></div>
      </div>
    </LoadingComponent>
  );
}

export { LoadingPage };
