export default () => {
  const menu = {
    header: {
      self: {},
      items: [
        {
          title: "Dashboards",
          root: true,
          alignment: "left",
          page: "dashboard",
          translate: "MENU.DASHBOARD"
        }
      ]
    },
    aside: {
      self: {},
      items: []
    }
  };

  menu.aside.items.push({
    title: "Dashboard",
    root: true,
    icon: "flaticon-analytics",
    page: "admin/dashboard",
    translate: "MENU.DASHBOARD",
    bullet: "dot"
  });

  menu.aside.items.push({
    title: "Perfis",
    root: true,
    icon: "flaticon-avatar",
    page: "admin/perfis",
    translate: "MENU.PROFILES",
    bullet: "dot"
  });

  menu.aside.items.push({
    title: "Usuários",
    root: true,
    icon: "flaticon-users",
    bullet: "dot",
    submenu: [
      {
        title: "Gerenciar Usuários",
        page: "admin/usuarios"
      },
      {
        title: "Treinamentos e Módulos",
        page: "admin/gerenciar-treinamentos-modulos"
      },
      {
        title: "Importação em Lote",
        page: "admin/importacoes?type=USER"
      }
    ]
  });

  if (process.env.REACT_APP_TURMA == "true") {
    menu.aside.items.push({
      title: "Empresas",
      root: true,
      icon: "flaticon-network",
      bullet: "dot",
      submenu: [
        {
          title: "Gerenciar Empresas",
          page: "admin/empresas"
        }
      ]
    });
  }

  menu.aside.items.push({
    title: "Desafios",
    root: true,
    icon: "flaticon-edit-1",
    bullet: "dot",
    submenu: [
      {
        title: "Gerenciar Desafios",
        page: "admin/desafios"
      },
      {
        title: "Avaliação de Respostas",
        page: "admin/respostas"
      }
    ]
  });
  if (process.env.REACT_APP_TURMA == "true") {
    menu.aside.items.push({
      title: "Aulas",
      root: true,
      icon: "flaticon-presentation-1",
      bullet: "dot",
      submenu: [
        {
          title: "Gerenciar Módulos",
          page: "admin/modulos"
        },
        {
          title: "Gerenciar Aulas",
          page: "admin/aulas"
        },
        {
          title: "Gerenciar Treinamentos",
          page: "admin/treinamentos"
        },
        {
          title: "Gerenciar Carreiras",
          page: "admin/carreiras"
        },
        {
          title: "Gerenciar Turmas",
          page: "admin/turmas"
        }
        // {
        //   title: "Gerenciar Períodos",
        //   page: "admin/periodos"
        // }
      ]
    });
  } else {
    menu.aside.items.push({
      title: "Aulas",
      root: true,
      icon: "flaticon-presentation-1",
      bullet: "dot",
      submenu: [
        {
          title: "Gerenciar Módulos",
          page: "admin/modulos"
        },
        {
          title: "Gerenciar Aulas",
          page: "admin/aulas"
        },
        {
          title: "Gerenciar Treinamentos",
          page: "admin/treinamentos"
        },
        {
          title: "Gerenciar Carreiras",
          page: "admin/carreiras"
        }
      ]
    });
  }

  menu.aside.items.push({
    title: "Conquistas",
    root: true,
    icon: "flaticon-trophy",
    page: "admin/conquistas",
    bullet: "dot"
  });

  menu.aside.items.push({
    title: "Regras de Pontuação",
    root: true,
    icon: "flaticon-interface-6",
    page: "admin/regras",
    bullet: "dot"
  });

  menu.aside.items.push({
    title: "Pontuação Avulsa",
    root: true,
    icon: "flaticon-gift",
    page: "admin/pontuacao-avulsa",
    bullet: "dot"
  });

  menu.aside.items.push({
    title: "Enviar E-mails",
    root: true,
    // icon: "flaticon-mail-1",
    icon: "flaticon-paper-plane",
    page: "admin/emails",
    bullet: "dot"
  });

  menu.aside.items.push({
    title: "Loja",
    root: true,
    icon: "flaticon-bag",
    bullet: "dot",
    submenu: [
      // {
      //   title: "Produtos",
      //   page: "admin/produtos"
      // },
      // {
      //   title: "Tamanho Produtos",
      //   page: "admin/produtos-tamanhos"
      // },
      {
        title: "Transações",
        page: "admin/transactions"
      }
    ]
  });

  return menu;
};
