import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  UtmParamsSet: "[UtmParams.Set] Action"
};

const INITIAL_STATE = {
  utmParams:{
    utmId: null,
    utm_campaign: null,
    utm_content: null,
    utm_medium: null,
    utm_source: null,
    utm_term: null,
  }

}

export const marketingReducer = persistReducer(
  { storage, key: "utmParams" },
  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.UtmParamsSet: {
        return { ...state, utmParams: action.payload };
      }
      default:
        return state;
    }
  }
);

export const marketingActions = {
  setUtmParams: utmParams => ({ type: actionTypes.UtmParamsSet, payload: utmParams })
};
